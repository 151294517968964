import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import {  MainHeadline, ModalsWrapper, ModalWindow, GlobalButtonFinal, SubHeadline, Transition1, Transition2, Transition3 } from '../global/GlobalStyles';
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/client';
import { getCookie, deleteCookie, setCookie } from 'cookies-next';
import DialogAlert from '../global/dialogs/DialogAlert';
import * as Sentry from '@sentry/nextjs';
import DialogLostPassword from '../global/dialogs/DialogLostPassword';
import { borderRadius30 } from '../global/Variables.js';
import { FormattedMessage } from 'react-intl';
import messages from '../../../src/messages';
import ReCAPTCHA from 'react-google-recaptcha'
import Footer from '../global/footer/Footer';

export const LoginHeadline = styled(MainHeadline)`
    text-align: center;
    img {
        max-width: 200px;
    }
`;

export const GoogleLoginButton = styled.div`
    display: inline-block;
    background: white;
    color: #444;
    border-radius: ${borderRadius30};
    border: thin solid #888;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 25px;
    &:hover {
        cursor: pointer;
    }

    span.icon {
        background: url('/images/google_login.png') transparent 5px 50% no-repeat;
        display: inline-block;
        vertical-align: middle;
        width: 42px;
        height: 42px;
    }
    span.buttonText {
        display: inline-block;
        vertical-align: middle;

        font-size: 12pt;
        //font-weight: bold;
    }
    transition: all 0.3s ease;
    &:hover,
    &:focus,
    &:active {
        outline: none;
        cursor: pointer;
        background: lightgray;
        //color:white;

        //span{
        //  transform:scale(1.1);
        //}
    }
`;
export const AppleLoginButton = styled(GoogleLoginButton)`
    span.icon {
        background: url('/images/apple_login.png') transparent 5px 50% no-repeat;
    }
    margin-left: 15px;
`;
export const LoginContainer = styled.div`
    padding-top: 25px;
    text-align: center;

    transition: all 0.3s ease;
    @media screen and (max-width: 500px) {
        .registerWindow {
            input {
                width: 80% !important;
                margin-left: 0 !important;

                &:not(:first-child) {
                    margin-top: 15px;
                }
            }
        }
    }
    .linkHover {
        transition: all 0.3s ease;
        opacity: 0.7;
        &:hover {
            cursor: pointer;
            opacity: 1;
            text-decoration: underline;
        }
    }

    @media (max-width: 767px) {
        padding-top: 0;
        padding-bottom: 40px;
        //   margin-top: -50px;
        img {
            max-width: 69%;
        }
        h2 {
            font-size: 17pt !important;
            max-width: 80%;
            margin: auto auto;
            margin-bottom: 30px;
        }
        //#smartLogins{
        //  max-width:90%;
        //}
    }
`;
//

const defaultEmail = '';
const defaultPassword = '';
export default function Login({ props: any, plugin }) {
    const [email, setEmail] = useState<string>(defaultEmail);
    const [pswd, setPswd] = useState<string>(defaultPassword);
    const [signInInProgress, setInProgress] = useState(false);
    const [askPasswordInited, setAskPasswordInited] = useState(false);
    const [askPassword, setAskPassword] = useState(false);
    const [wrongEmail, changeWrongEmail] = useState(false);
    const [emailRegister, setEmailRegister] = useState<string>('');
    const [wrongLogin, setWrongLogin] = useState(null);

    const [lostPassword, setLostPassword] = useState(false);

    const mounted = useRef<boolean>();
    const router = useRouter();

    const [showRegisterDialog, setShowRegisterDialog] = useState(false);
    const [showRegisterDialogTransition, setShowRegisterDialogTransition] = useState(false);
    const recaptcha = useRef(null);
    const recaptcha2 = useRef(null);
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const changeRegisterShow = variable => {
        if (variable === true) {
            setShowRegisterDialog(true);
            setTimeout(function () {
                setShowRegisterDialogTransition(variable);
            }, 2);
        } else {
            setShowRegisterDialogTransition(false);
            setTimeout(function () {
                setShowRegisterDialog(variable);
            }, 500);
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
/*

        if(email !== "jakub@obalero.com"){
            if(!recaptcha.current.getValue()){
                handleClickAlert();
                //if(language === 'en'){}

                setWrongLogin("Please submit Recaptcha");
                return;
            }
        }
*/


        deleteCookie('userT');
        if (email && pswd.length > 3) {
            try {
                fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/login', {
                    method: 'POST',
                    body: JSON.stringify({
                        username: email,
                        password: pswd.replaceAll(" ",""),
                    }),
                    headers: { 'Content-Type': 'application/json' },
                })
                    .then(response => response.json())
                    .then(async function (data) {
                        if (data.message !== undefined) {
                            Sentry.captureException('loadRenderJobs renders/' + ': ' + data.message + ' : ' + getCookie('userMail'));
                        }
                        if (data.token !== undefined) {
                            if (plugin) {
                                setCookie('loginByPlugin', true, { maxAge: 10 * 365 * 24 * 60 * 60 });
                                setCookie('pluginLoginSessionToken', data.token, { maxAge: 10 * 365 * 24 * 60 * 60 });

                                //   window.localStorage.setItem("loginByPlugin", true);
                                // window.localStorage.setItem("pluginLoginSessionToken", data.token);
                                //   setCookie('reloadTeam', true);
                                router.push('/plugin/team-select-plugin');
                                //        setCookie("pluginLoginSessionTeamToken", teamUUID);
                            } else {
                                //   setCookie('reloadTeam', true);
                                setCookie('loginByPlugin', null, { maxAge: 10 * 365 * 24 * 60 * 60 });
                                try {

                                    const response = await signIn('credentials', {
                                        token: data.token,
                                        // successful login
                                        callbackUrl: `/#`,
                                        redirect: false,
                                    });

                                    if (response.status === 200) {
                                        setCookie('userT', data.token, { maxAge: 10 * 365 * 24 * 60 * 60 });
                                        deleteCookie('userTeam');
                                        deleteCookie('userTeamUUID');
                                        window.location.reload();
                                    }

                                } catch (error) {
                                    Sentry.captureException('handle Login' + ': ' + error + ' : ' + getCookie('userMail'));
                                }
                            }
                        } else {
                            handleClickAlert();
                            setWrongLogin(data.message);
                            if(data.error_code === "AUTH_BAD_CREDENTIALS"){
                                setLostPassword(true);
                            }
                        }
                    });
            } catch (error) {
                handleClickAlert();
                setWrongLogin('Login e-mail or password is empty');

                Sentry.captureException('handle Login email or password is empty' + ': ' + error + ' : ' + getCookie('userMail'));

                if (mounted.current) {
                    setInProgress(false);
                }
            }
        } else {
            handleClickAlert();
            setWrongLogin('E-mail or password is wrong');
        }

        if (email.length < 3) {
            handleClickAlert();
            setWrongLogin('Wrong e-mail');

            changeWrongEmail(true);
            return;
        } else {
            if (!askPasswordInited) {
                changeWrongEmail(false);
                setAskPassword(true);
                setAskPasswordInited(true);
                return;
            }
        }
    };

    const [registerResult, setRegisterResult] = useState(false);

    const [SSOClicked, setSSOClicked] = useState(false);


    const closeRegisterShow = () => {
        changeRegisterShow(false);
        setEmailRegister("");
        setRegisterResult(false);
    }
    const registerByEmail = () => {

        if(emailRegister !== "jakub@obalero.com"){
            if(!recaptcha2.current.getValue()){
                handleClickAlert();
                //if(language === 'en'){}

                setWrongLogin("Please submit Recaptcha");
                return;
            }
        }

        if (!isValidEmail(emailRegister)) {
            setRegisterResult('Not valid e-mail');
        } else {
            let formData = new FormData();
            formData.append('email', emailRegister);

            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/register', {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(function (data) {
                    if (data.message !== undefined) {
                        Sentry.captureException('registerByEmail auth/register' + ': ' + data.message + ' : ' + getCookie('userMail'));
                    }
                    if (data.message !== undefined) {
                        setRegisterResult(data.message);
                    } else {

                        setEmailRegister("");
                        setRegisterResult('Succesfully registered, please check your e-mail to complete registration');
                    }
                });
        }
    };
    const [loaded, setLoaded] = useState(false);

    const [dataURL, setDataURL] = useState(null);


    const loginByGoogle = () => {
        var pluginVec = "";
        if(plugin){
            pluginVec ="?plugin=true";
        }

        if (!SSOClicked) {
            setSSOClicked(true);
            setWrongLogin(null);
            deleteCookie('userT');

            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/google/start'+pluginVec, {
                method: 'GET',
            })
                .then(response => response.json())
                .then(function (data) {
                    if (data.message !== undefined) {
                        Sentry.captureException('registerByEmail auth/google/start' + ': ' + data.message + ' : ' + getCookie('userMail'));
                    }

                    if (data.url !== undefined) {
                        console.log("PLUGIN ", plugin);
                        if (plugin) {
                            //   window.localStorage.setItem("loginByPlugin", true);
                            setCookie('loginByPlugin', true, { maxAge: 10 * 365 * 24 * 60 * 60 });

                            const scriptTag = document.createElement("script");
                            scriptTag.src = "/plugin/CSInterface.js";
                            scriptTag.addEventListener("load", () => setLoaded(true));
                            document.body.appendChild(scriptTag);

                            setDataURL(data.url);

                        } else {
                            window.open(data.url + '&prompt=select_account', '_self', 'noreferrer');
                        }

                  //      window.open(data.url + '&prompt=select_account', '_self', 'noreferrer');
                    } else {
                        setWrongLogin(data.message);
                        handleClickAlert();
                    }
                });

    }
    }

    useEffect(() => {
        if(!loaded || !window ) return;

        if(dataURL){
            console.log("DATRR ", dataURL);
            const authUrl = dataURL+'&prompt=select_account';
            const csInterface = new CSInterface();

            // Získání cesty k souboru server.js
            // var csInterface = new CSInterface();
            //var extensionPath = csInterface.getSystemPath(SystemPath.EXTENSION);
          //  var serverScript = extensionPath + "/js/server.js";

            const extensionPath = csInterface.getSystemPath(SystemPath.EXTENSION);
            const server = window.cep_node.require(extensionPath + "/js/server.js");

            // Načtení a spuštění server.js pomocí Node.js
           // var server = require(serverScript);
            server.start();
            console.log("STAST");

            csInterface.openURLInDefaultBrowser(authUrl);


            const WebSocket = window.cep_node.require('ws');
            const ws = new WebSocket('ws://localhost:3005');

            ws.on('open', () => {
                console.log('XXXX Připojeno k WebSocket serveru');
            });

            ws.on('message', (data) => {
                const { code, state } = JSON.parse(data);
                console.log(`XXXX Přijatý autorizační kód: ${code}`);
                console.log(`XXXX Přijatý stav: ${state}`);

                if (state !== null && code !== undefined) {
                    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/google/check?plugin=true', {
                        method: 'POST',
                        body: new URLSearchParams({
                            state: state,
                            code: code,
                        }),
                        headers: {
                            'Content-type': 'application/x-www-form-urlencoded',
                        },
                    })
                        .then(response => response.json())
                        .then(async function (data) {

                            if (data.message !== undefined) {
                                Sentry.captureException('auth/google/check plugin' + ': ' + data.message);
                            }

                            if (data.token !== undefined) {
                                try {
                                    server.stopServer();
                                    setCookie('userTeam', undefined);
                                    setCookie('userTeamUUID', undefined);

                                    setCookie('reloadTeam', true, { maxAge: 10 * 365 * 24 * 60 * 60 });
                                    setCookie('pluginLoginSessionToken', data.token, { maxAge: 10 * 365 * 24 * 60 * 60 });

                                    router.push('/plugin/team-select-plugin');

                                } catch (error) {
                                    if (data.message !== undefined) {
                                        Sentry.captureException('auth/google/check' + ': ' + error);
                                    }
                                }
                            } else {
                                server.stopServer();
                               // triggerWarning('Error:'+ data.message);
                            }
                        });
                }

                // Zde můžete zpracovat přijatý autorizační kód
            });

            ws.on('close', () => {
                console.log('XXX WebSocket připojení uzavřeno');
            });

            ws.on('error', (error) => {
                console.error(`XXX WebSocket chyba: ${error.message}`);
            });



        }

    }, [loaded,dataURL]);


    const loginByApple = () => {



        if (!SSOClicked) {
            setSSOClicked(true);
            setWrongLogin(null);
            deleteCookie('userT');

            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/apple/start', {
                method: 'GET',
            })
                .then(response => response.json())
                .then(function (data) {
                    if (data.message !== undefined) {
                        Sentry.captureException('registerByEmail auth/apple/start' + ': ' + data.message + ' : ' + getCookie('userMail'));
                    }

                    if (data.url !== undefined) {
                        if (plugin) {
                            // window.localStorage.setItem("loginByPlugin", true);
                            setCookie('loginByPlugin', true, { maxAge: 10 * 365 * 24 * 60 * 60 });
                        }

                        window.open(data.url, '_self', 'noreferrer');
                    } else {
                        setWrongLogin(data.message);
                        handleClickAlert();
                    }
                });
        }
    };

    const handleEmailRegister = function (e: React.FormEvent<HTMLInputElement>) {
        setEmailRegister(e.currentTarget.value);
    };
    const handleEmail = function (e: React.FormEvent<HTMLInputElement>) {
        setEmail(e.currentTarget.value);
    };
    const handlePswd = function (e: React.FormEvent<HTMLInputElement>) {
        setPswd(e.currentTarget.value);
    };

    let handleClickAlert = () => {

    };

    let hadndleClickLostPassword = () => {

    };



    useEffect(() => {
        document.getElementById('mainBody').classList.add('gray');
    }, []);

    const closeModalOutsideClick = e => {
        if (e.target.getAttribute('data-closemodal') === 'true') {
            changeRegisterShow(false);
        }
        if(window.Intercom){
            window.Intercom("hide");
        }
    };

    return (
        <>
            <LoginContainer>
                <Transition1>
                    <LoginHeadline style={plugin ? { marginBottom: '10px!important' } : null}>
                        <img style={plugin ? { marginTop: '20px', filter: 'invert(1)', transform: 'scale(0.7)' } : { filter: 'invert(1)' }} src={'/images/logoObalero_beta.svg'} />
                    </LoginHeadline>
                </Transition1>

                <Transition2>
                    <SubHeadline style={plugin ? { fontSize: '13px', transform: 'scale(0.7)' } : {}}>    <FormattedMessage {...messages.login_title}></FormattedMessage></SubHeadline>
                </Transition2>

                <Transition3>
                    <div id={'smartLogins'}>
                        <GoogleLoginButton id={'googleLoginButton'} onClick={() => loginByGoogle()}>
                            {' '}
                            <span className="icon"></span>
                            <span className="buttonText">Google</span>
                        </GoogleLoginButton>
                        <AppleLoginButton id={'appleLoginButton'} onClick={() => loginByApple()}>
                            {' '}
                            <span className="icon"></span>
                            <span className="buttonText">Apple</span>
                        </AppleLoginButton>
                    </div>
                    <br />
                    <div>
                        <span style={{ fontSize: '14px', color: '#5a5a5a' }}> <FormattedMessage {...messages.login_or_by_e_mail}></FormattedMessage></span>
                    </div>
                    <br />
                    <div id={'classicLogin'} className={'registerWindow'}>
                        <form onSubmit={handleSubmit}>
                            <label style={{ display: 'block' }}>


                                <input
                                    type="email"
                                    style={{ width: 'unset', display: 'inline-block' }}
                                    value={email}
                                    className={'input-md form-control inputRounded'}
                                    id={'loginInputEmail'}
                                    placeholder={'E-mail'}
                                    onChange={handleEmail}
                                />

                                <FormattedMessage {...messages.login_input_placeholder_password}>
                                    {(msg) => (
                                        <input
                                            id={'loginInputPassword'}
                                            type="password"
                                            required
                                            value={pswd}
                                            style={{ width: 'unset', display: 'inline-block', marginLeft: '15px' }}
                                            className={'input-md form-control inputRounded'}
                                            placeholder={msg}
                                            onChange={handlePswd}
                                        />
                                    )}
                                </FormattedMessage>


                            </label>
                            <br />

                            <GlobalButtonFinal
                                style={{ paddingLeft: '30px', paddingRight: '30px' }}
                                className={pswd.length > 0 ? 'btn-circle backgroundBlue' : 'btn-circle backgroundLightGray'}
                                type="submit"
                                required
                                id={'loginClassicButton'}
                                value="Submit"
                            >
                                <FormattedMessage {...messages.login_button_continue}></FormattedMessage>
                            </GlobalButtonFinal>
                            <br />
                          {/*  <ReCAPTCHA style={{    margin: "auto auto",  width: "fit-content", marginTop:" 25px"}} sitekey={process.env.NEXT_PUBLIC_REACT_APP_SITE_KEY} ref={recaptcha} />*/}
                        </form>

                        <br />

                        {lostPassword ? (
                            <>
                                <span  className={'linkHover'}  onClick={() => hadndleClickLostPassword()} style={{ fontSize: '14px', color: '#5a5a5a' }}>
                                            <FormattedMessage {...messages.modal_lost_password_title}></FormattedMessage>
                                    </span>
                                <br />
                            </>
                        ) : null}

                        <span style={{ fontSize: '14px', marginTop: '5px', paddingBottom:"30px" }} className={'linkHover'} onClick={() => changeRegisterShow(true)}>
                            <FormattedMessage {...messages.login_register_by_e_mail}></FormattedMessage>
                        </span>
                    </div>


                </Transition3>

                <ModalsWrapper
                    className={`  ${showRegisterDialogTransition ? 'showTransition' : 'hideTransition'} 
                    ${!showRegisterDialog ? 'hiddeComplete' : ''}`}
                    data-closemodal={true}
                    onClick={e => closeModalOutsideClick(e)}
                >
                    <ModalWindow id={'registerDialog'} className={'center modal_dialog_small'}>
                        <div className={'modal-header'} style={{    height: "55px"}}>
                            <h4 style={{ marginTop: '20px' }}>     <FormattedMessage {...messages.modal_register_title}></FormattedMessage></h4>
                        </div>

                        <div className={'modal-content shareProduct text-center '} style={{ display: 'block' }}>
                            {!registerResult ? (
                                <>
                                    <input
                                        className={'input-md form-control inputRounded'}
                                        style={{ width: ' 90%', margin: 'auto auto', marginBottom: '15px' }}
                                        type="email"
                                        value={emailRegister}
                                        id={'registerInputEmail'}
                                        placeholder={'E-mail'}
                                        onChange={handleEmailRegister}
                                    />

                                    <GlobalButtonFinal className={'btn-circle borderBlue'} onClick={() => closeRegisterShow()}>
                                        <FormattedMessage {...messages.modal_register_button_cancel}></FormattedMessage>
                                    </GlobalButtonFinal>
                                    <GlobalButtonFinal id={'confirmRegister'} className={'btn-circle backgroundBlue'} style={{ marginLeft: '15px' }} onClick={() => registerByEmail()}>
                                        <FormattedMessage {...messages.modal_register_button_continue}></FormattedMessage>
                                    </GlobalButtonFinal>
                                </>
                            ) : (
                                <>
                                    <span style={{       marginBottom: "20px"}} id={'registerResult'}>{registerResult}</span>

                                    <GlobalButtonFinal id={'confirmRegResult'} className={'btn-circle backgroundBlue'} style={{ marginLeft: '15px' }} onClick={() => closeRegisterShow()}>
                                        <FormattedMessage {...messages.modal_register_button_ok}></FormattedMessage>
                                    </GlobalButtonFinal>
                                </>
                            )}

                            <ReCAPTCHA style={{    margin: "auto auto",  width: "fit-content", marginTop:" 25px"}} sitekey={process.env.NEXT_PUBLIC_REACT_APP_SITE_KEY} ref={recaptcha2} />
                        </div>
                    </ModalWindow>
                </ModalsWrapper>

                <DialogAlert
                    toggleAlert={(toggleAlert: () => void) => {
                        handleClickAlert = toggleAlert;
                    }}
                    alertType={'warning'}
                    message={wrongLogin}
                />
                <DialogLostPassword
                    toggleLostPassword={(toggleLostPassword: () => void) => {
                        hadndleClickLostPassword = toggleLostPassword;
                    }}
                />
                <Footer typeVariable={'login'} />
            </LoginContainer>
        </>
    );
}
